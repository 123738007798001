<template>
  <div class="content-container">
    <PageTitle :title="$t('MY_CLASSES.TITLE')" class="page-title">
      <template #extra-content>
        <BasicButton v-if="myClasses && classes.length > 0" :button-text="$t('CREATE_CLASS_TEXT')" @click="createClass()"/>
      </template>
    </PageTitle>

    <h1 v-if="classes.length > 0" class="page-section-title section-title">{{ $t('MY_CLASSES.OVERVIEW') }}</h1>
    <i18n-t v-if="classes.length > 0" class="page-section-text section-text" keypath="MY_CLASSES.SUBTEXT" tag="p">
      <template #date>
        <span style="font-weight: 500"> {{ $t('MY_CLASSES.DATE') }} </span>
      </template>
    </i18n-t >
    <h1 v-if="classes.length > 0" class="page-section-title section-title">{{ $t('MY_CLASSES.MY_CLASSES') }}</h1>
    <p v-if="myClasses.length === 0 && classes.length > 0" class="page-section-text section-text" >{{ $t('MY_CLASSES.NO_CLASSES') }} </p>

    <!-- Table -->
    <CustomTable
      :less-padding="true"
      :options="classesTableOptions"
      :table-data="myClasses"
      :empty-state-data="(myClasses.length === 0 && otherClasses.length === 0) ? emptyTableData : null"
      :table-class="'my-classes'">
      <template #teacher-head>
        &nbsp;<img v-if="!isAccountVerified" src="../../../assets/icons/icn_password.svg"/>
      </template>
      <template #name="props"><span class="class-name">{{ props.rowData.name }}</span></template>
      <!-- TODO: This will probably be an array. -->
      <template #teacher="props">
        <div>{{ concatTeachers(props.rowData.teachers) }}</div>
      </template>
      <template #group="props">
        <div>{{ lookupNameById(props.rowData.classTypeId) }}</div>
      </template>
      <template #actions="props">
        <!-- TODO: Add translation strings. -->
        <div class="action-button-group">
          <ActionButton :text="'Verwijderen'"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="beforeClassRemoval(props.rowData)"/>
          <ActionButton :text="'Bewerken'"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="editClass(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <h1 v-if="classes.length > 0" class="page-section-title section-title">{{ $t('MY_CLASSES.OTHER_CLASSES') }}</h1>
    <p v-if="otherClasses.length === 0 && classes.length > 0" class="page-section-text section-text" >{{ $t('MY_CLASSES.NO_OTHER_CLASSES') }} </p>
    <!-- Table -->
    <CustomTable
      :less-padding="true"
      :options="classesTableOptions"
      :table-data="otherClasses"
      :table-class="'my-classes'">
      <template #name="props"><span class="class-name">{{ props.rowData.name }}</span></template>
      <template #teacher-head>
        &nbsp;<img v-if="!isAccountVerified" src="../../../assets/icons/icn_password.svg"/>
      </template>
      <!-- TODO: This will probably be an array. -->
      <template #teacher="props">
        <div v-if="isAccountVerified">{{ concatTeachers(props.rowData.teachers) }}</div>
        <div v-else class="blurred">{{ $t('MY_CLASSES.HIDDEN_TEACHER_NAME') }}</div>
      </template>
      <template #group="props">
        <div>{{ lookupNameById(props.rowData.classTypeId) }}</div>
      </template>
      <template #actions="props">
        <!-- TODO: Add translation strings. -->
        <div v-if="profile.isApproved" class="action-button-group">
          <ActionButton :text="'Verwijderen'"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="beforeClassRemoval(props.rowData)"/>
          <ActionButton :text="'Bewerken'"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="editClass(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Delete Class modal -->
    <BasicModal/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { computed } from '@vue/reactivity'
import CustomTable from '@/components/table/CustomTable'
import {
  getTableOptions,
  MY_CLASSES_OPTIONS,
  MY_CLASSES_OPTIONS_UNAPPROVED
} from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { useStore } from 'vuex'
import { RESET_ACTIVE_BASIC_MODAL, RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA } from '@/store/mutations'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { useRouter } from 'vue-router'
import { GET_SCHOOL_CLASSES } from '@/store/modules/teacherPortal/actions'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'
import BasicButton from '@/components/elements/basic/BasicButton'
import { schoolClassTypes } from '@/utils/data/schoolClassTypes'
import { GET_CLASS_TYPES } from '@/store/actions'

export default {
  name: 'MyClasses',
  components: {
    BasicModal,
    ActionButton,
    CustomTable,
    PageTitle,
    BasicButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const emptyTableData = createEmptyTableData('CLASSES_TEACHER', createClass, require('@/assets/icons/icn_class.svg'))
    store.dispatch(GET_CLASS_TYPES);

    const profile = computed(() => store.getters.getTeachersProfile)
    const classTypes = computed(() => store.getters.getClassTypes);
    const isAccountVerified = computed(() => profile.value.isApproved)
    const classesTableOptions = computed(() => getTableOptions(isAccountVerified.value ? MY_CLASSES_OPTIONS : MY_CLASSES_OPTIONS_UNAPPROVED))

    /** Classes **/
    manageSearch()
    const classes = computed(() => store.getters.getSchoolClasses)

    const myClasses = computed(() => classes.value ? classes.value.filter(value => value.teachers.some(value => value.id === profile.value.teacherId)) : [])

    const otherClasses = computed(() => classes.value ? classes.value.filter(value => value.teachers.every(value => value.id !== profile.value.teacherId)) : [])

    function createClass() {
      router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.CREATE_CLASS })
    }

    function lookupNameById(id) {
      if (!classTypes?.value) return '';
      return classTypes?.value.find(value => value.id === id)?.name
    }

    function concatTeachers(teachers) {
      return (teachers.map(value => value.name)).join(', ')
    }

    function searchClasses(payload) {
      return store.dispatch(GET_SCHOOL_CLASSES, payload)
    }
    /** Edit Class **/
    function editClass(aClass) {
      router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.EDIT_CLASS, params: { classId: aClass.id } })
    }


    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      searchClasses(payload)
    }

    /** Delete Class **/
    function beforeClassRemoval(aClass) {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.REMOVE_CLASS(aClass.name),
          cancelCallback: null,
          executeCallback: onClassRemoval,
          data: aClass.id
        },
        activeModal: MODAL_NAMES.REMOVE_CLASS
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function onClassRemoval(classId) {
      removeClass(classId).then(manageSearch)
      store.commit(RESET_ACTIVE_BASIC_MODAL)
      store.commit(RESET_BASIC_MODAL_DATA)
    }

    function removeClass(classId) {
      return ApiHandler.delete(`teacher/delete-school-class/${classId}`)
    }

    return {
      ROUTE_NAMES_TEACHER_PORTAL,
      classesTableOptions,
      emptyTableData,
      isAccountVerified,

      /** Classes **/
      classes,
      myClasses,
      otherClasses,
      editClass,
      concatTeachers,
      lookupNameById,
      profile,
      createClass,

      /** Delete Class **/
      beforeClassRemoval,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.button-blue {
  margin-bottom: 0;
  height: rem(33);
}

.button-red {
  margin-bottom: 0;
  height: rem(33);
}

.section-title {
  margin-bottom: rem(11);
  margin-top: rem(30);
}

.section-text {
  margin-bottom: rem(30);
}

.no-result-button {
  margin-top: rem(51);
}

.class-name {
  color: var(--blue_link);
  font-weight: 500;
}

.blurred {
  filter: blur(5px)
}
</style>
